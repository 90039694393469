import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SideBarMenu, SidebarLink, SidebarLinkS } from './SideBarElements'
const Sidebar = ({isOpen, toggle}) => {

  
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle} >
            <Icon onClick={toggle}>
                <CloseIcon/> 
            </Icon>
            <SidebarWrapper>
                <SideBarMenu>
                    <SidebarLink  to='/' className="item-nav" onClick={toggle}>Inicio</SidebarLink>
                    <SidebarLink  to='/nosotros' className="item-nav" onClick={toggle}>Nosotros</SidebarLink>
                    <SidebarLink to='/catalogo' className="item-nav" onClick={toggle}>Catálogo</SidebarLink>
                    <SidebarLinkS to='contacto' smooth={true} duration={200} spy={true} exact='true' offset={-100} onClick={toggle}>Contacto</SidebarLinkS>
                </SideBarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar