import React, {Component} from "react";
import { Col, Form, Button, Row } from 'react-bootstrap'
import axios from 'axios'
import Swal from 'sweetalert2'
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

class Footer extends Component {
  constructor(props){
    super(props)

    this.state = {
        categoria: '',
        nombre: '',
        correo: '',
        tel: '',
    }
}

changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
}
submitHandler = e => {
  e.preventDefault();
  const urlPost = 'https://inchcapeguatemala.com/admin/api/contact';
  var bcolor = "#00a167";
  Swal.fire({
      title: "Cargando",
      text: "",
      type: "info",
          html: "Estamos recibiendo tus datos... <br/>por favor espera un momento",
      showCancelButton: false,
      showConfirmButton: false
  });
  
   axios({
      method: 'post',
      url: urlPost,
      data: this.state
      })
      .then(response =>{
          console.log(this.state)
          console.log(response)
        
          e.target.reset();
          Swal.fire({
              title: response.statusText,
              text: response.data,
              showConfirmButton: true,
              showCancelButton: false,
              confirmButtonColor: bcolor
          });
          
          
      })
      .catch(error => {
          console.log(error)
          Swal.fire({
              title: "Ocurrió un error",
              text: "Por favor, intentalo nuevamente.",
              timer: 3000,
              showConfirmButton: true,
              confirmButtonColor: bcolor });

      })
}

    render(){
    const { categoria, nombre, correo, tel } = this.state

        return (

        <footer>
            <div className="form">
              <br />
             
              <Form className="contact__form" onSubmit={this.submitHandler} id="contacto">
                                <h1>Contáctanos</h1> 
                                <Col lg={12} xs={12}>
                                    <Form.Group controlId="formBasicSelect">
                                        <Form.Label>Categoria</Form.Label>
                                        <Form.Control as="select" value={categoria} name="categoria" onChange={this.changeHandler} required>
                                            <option disabled>Selecciona una categoria</option>
                                            <option value="1">Repuestos de  Automóvil</option>
                                            <option value="2">Repuestos comerciales (Freightliner)</option>
                                        </Form.Control>
                                    </Form.Group> 
                                 </Col> 

                                 <Col lg={12} xs={12}>
                                     <Form.Group className="mb-3" controlId="formBasicName">
                                     <Form.Label>Nombre Completo</Form.Label>

                                         <Form.Control type="text" placeholder="Nombre" className="form__input form-input" name="nombre" value={nombre} onChange={this.changeHandler} required />
                                     </Form.Group>
                                 </Col>
                                 <Col lg={12} xs={12}>
                                     <Form.Group className="mb-3" controlId="formBasicEmail">
                                     <Form.Label>Correo Electrónico</Form.Label>

                                         <Form.Control type="email" placeholder="Correo electrónico" className="form__input form-input" name="correo" value={correo} onChange={this.changeHandler} required/>
                                     </Form.Group>
                                 </Col>
                                 <Col lg={12} xs={12}>
                                   
                                     <Form.Group className="mb-3 " controlId="formBasicPhone">
                                     <Form.Label>Teléfono</Form.Label>

                                         <Form.Control type="text" placeholder="Número de teléfono" className="form__input form-input" name="tel" value={tel} onChange={this.changeHandler} required />
                                     </Form.Group>
                                 </Col>
                              
                                 <Col lg={12} xs={12}>
                                     <Button variant="success" type="submit" className="form__button form-button">
                                         Enviar
                                     </Button>
                                 </Col>
                             </Form>
            
            
            </div>
            <div className="about">
            <h1>
            Inchcape Guatemala
            </h1>
           <p><FaPhoneAlt style={{color: "#000", fontSize: "22px", marginRight: "5px"}}></FaPhoneAlt> 2316-8300</p>
           <p>

           <a href="https://www.facebook.com/MercedesBenzGuatemala" target="_blank" className="social-section">
                   <span style={{color: "#4267B2", fontSize: "25px", marginRight: "5px"}}>
                       <FaFacebook></FaFacebook> 
                    </span>
                    Mercedes Benz Guatemala
               </a>
               <br/>
               <a href="https://www.facebook.com/FreightlinerCentroamerica" target="_blank" className="social-section">
                   <span style={{color: "#4267B2", fontSize: "25px", marginRight: "5px"}}>
                       <FaFacebook></FaFacebook> 
                    </span>
                    Freightliner Centroamerica
               </a>
               <br/>
               <a href="https://www.linkedin.com/company/mercedes-benz-guatemala/" target="_blank" className="social-section">
                   <span style={{color: "#0e76a8", fontSize: "25px", marginRight: "5px"}}>
                       <FaLinkedin></FaLinkedin> 
                    </span>
                Mercedes Benz Guatemala
               </a>
               <br/>
               <a href="https://www.instagram.com/mercedesbenz_gt/" target="_blank" className="social-section">
                   <span  style={{fontSize: "25px", marginRight: "5px"}} className="instagram">
                       <FaInstagram ></FaInstagram> 
                    </span>
                    @mercedesbenz_gt
               </a>

{/* <Row>
<Col lg={6} xs={12}>
         
            </Col>
            <Col lg={6} xs={12}>
           
            </Col>
            <Col lg={6} xs={12}>
            
            </Col>
            <Col lg={6} xs={12}>
           
            </Col>
</Row> */}
             
               
             
               </p>
           <br/>
            <h5><b>VEHÍCULOS COMERCIALES</b></h5>
             <p>
              
              <FaMapMarkerAlt></FaMapMarkerAlt> Calzada Aguilar Batrés 27-98 Ciudad de Guatemala
              
              </p>

              <h5><b>AUTOS MERCEDES-BENZ</b></h5>

              <p>
              <FaMapMarkerAlt></FaMapMarkerAlt> 20 Calle 10-91, zona 10
              </p>
            </div>
        </footer>
        
        );
      }
}   
 
export default Footer;
