import React from 'react'
import image2 from './../assets/img/loading.gif'
const Product = ({productos, loading}) => {

    if(loading){
        return <div className="container-img">
        <img src={image2} alt="category2"className="loading-image"/>
        </div>
    }
    return (
        <div>
              <div className="row">
             
         {
        productos.status == 0 ? <h4>No hay productos </h4>
        : productos && productos.map((producto)=>(
          
            <div className="col-6 col-sm-6 col-md-3 " key={producto.id}>
            <figure className=" product">
                <div className="img-wrap"> 
                    
                    <img src={`https://inchcapeguatemala.com/admin/uploads/${producto.image}`} className="imgprod" />
                    <div className="container-title">
                        <h4>{producto.name}</h4>
                    </div>
                    
                </div> 
                <figcaption className="info-wrap">
                    <div className="fix-height">
                    
                        <div className="price-wrap mt-2" style={{padding:"5px 10px"}}>
                            N&uacute;mero de parte: <span className="price" style={{fontWeight:"900"}}>{producto.part_number}</span><br/>
                            <span className="price-old" >{producto.subcatname}</span>
                        </div>
                    </div>
                    {
                        producto.category_id == 1 ?
                        <a href={`https://api.whatsapp.com/send?phone=50230479079s&text=Hola%2C+Quisiera+cotizar+el+producto%3A+${encodeURIComponent(producto.name)}+-+${encodeURIComponent(producto.part_number)}+del+Sitio+de+Repuestos+MB+Autos`} className="btn btn-comprar" target="_blank">Comprar ahora</a>
                        : <a href={`https://api.whatsapp.com/send?phone=50253070040&text=Hola%2C+Quisiera+cotizar+el+producto%3A+${encodeURIComponent(producto.name)}+-+${encodeURIComponent(producto.part_number)}+del+Sitio+de+Repuestos`} className="btn btn-comprar" target="_blank">Comprar ahora</a>
                        }
              
                    
                </figcaption>
            </figure>
        </div> 
           ))}
            


            </div> 
        </div>
    )
}

export default Product
