import React, {Component} from "react";
import banner from './../assets/img/bannerhome.jpg'
class Banner extends Component {
    
    render(){

        return (

            <div className="banner">
                <img src={banner} alt="banner" className="banner-image" />
            </div>

        );

      }
}   
 
export default Banner;
