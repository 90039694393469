import React from 'react'
import { useState, useEffect } from 'react'
import { FaBars } from 'react-icons/fa'
import logo from './../../assets/img/logomain.png'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLink, NavLinkS} from './NavbarElements'
import { animateScroll as scroll } from 'react-scroll'

const Navbar = ({ toggle }) => {

  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if(window.screenY >= 80){
      setScrollNav(false);
    } else{
      setScrollNav(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () =>{
    scroll.scrollToTop();
  }


    return (
        <>
          <Nav scrollNav={scrollNav}>
          <NavbarContainer>
              <NavLogo to='/'>
              <img src={logo} alt="" className="logo" /> 
              </NavLogo>
              <MobileIcon onClick={toggle}>
                <FaBars/>
              </MobileIcon>
              <NavMenu>
                <NavItem>
                    <NavLink to='/' className="item-nav">
                    Inicio
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to='/nosotros' className="item-nav">
                    Nosotros
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to='/catalogo' className="item-nav" >
                        Catalogo
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLinkS to='contacto' smooth={true} duration={200} spy={true} exact='true' offset={-100}  className="item-nav" >
                        Contacto
                    </NavLinkS>
                </NavItem>
                
              </NavMenu>
          </NavbarContainer>
          </Nav>  
        </>
    )
}

export default Navbar;
