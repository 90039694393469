import React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import image1 from './../assets/img/categoria1.png'
import image2 from './../assets/img/categoria2.png'
import image3 from './../assets/img/categoria3.png'
import fre from './../assets/img/fre.png'
import ej2 from './../assets/img/ej2.jpg'
import ej3 from './../assets/img/ej3.jpg'
import { 
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Link
   } from 'react-router-dom'
import axios from 'axios'
import Product from '../components/Product'
import Pagination from '../components/Pagination';
const Catalogo = () => {

    const [productos, setProductos] = React.useState([]);
    const [gridProductos, setGridProductos] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(20);
    const [busqueda, setBusqueda] = React.useState([]);

    const peticionGET = async() => {
        setLoading(true)

        await axios.get("https://inchcapeguatemala.com/admin/api/products/a6f6f0aa3af20f401a05ad1d1e5d8624ae5e3e5a41efae069708d731ea9a714a")
            .then(response=>{
               // Set it in header for the rest of the axios requests.
                //console.log(response.data.products)
                setProductos(response.data.products);
                setGridProductos(response.data.products);
                setLoading(false)

            }).catch(error=>{
                console.log(console.error)
            })

    }
    const handleChange=e=>{
        setBusqueda(e.target.value);
        filtrar(e.target.value);
    }

    const filtrar=(terminoBusqueda)=>{
        var resultadosBusqueda=gridProductos.filter((elemento)=>{
          if(elemento.name.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
          || elemento.company.name.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
          ){
            return elemento;
          }
        });
        setProductos(resultadosBusqueda);
      }
      const {name}= useParams()
      console.log(useParams());
      const [categoria, setCategoria] = React.useState([])
       React.useEffect(() => {
          // console.log("On readasdasysadas");
          peticionGET();
           
  
          
       },[])


       const indexOfLastProducts = currentPage * productsPerPage;
       const indexOfFirstProducts = indexOfLastProducts - productsPerPage;
       
       const paginate = pageNumber => setCurrentPage(pageNumber);
        const currentProducts = productos.slice(indexOfFirstProducts, indexOfLastProducts)
        const total = Math.ceil(productos.length / productsPerPage)
    return (
        <div className="">
        <div className="container-fluid" style={{background: "#cccccc"}}>
        <main className="productos-todos" >

        <div className="category2">
            <Link to={`/catalogo/categoria-automovil`}>
            <img
                src={ej2}
                alt="category-image2"
                className="category-image"
                />
                <img
                src={image2}
                alt="category2"
                className="category-image"
                />
            </Link>
            </div>
            <div className="category3">
            <Link to={`/catalogo/categoria-freightliner`}>
                <img
                src={fre}
                alt="category-image3"
                className="category-image"
                />
                <img
                src={image3}
                alt="category3"
                className="category-image"
                />
            </Link>
            </div>
            <div className="category1">
            <Link to={`/catalogo/categoria-vehiculoscormerciales`}>
                <img
                src={ej3}
                alt="category-image3"
                className="category-image"
                />
                <img
                src={image1}
                alt="category1"
                className="category-image"
                />
            </Link>
            </div>
  </main>
        </div>

 
   {/* PRODUCTOS GRID */}
   <div className="container-fluid" style={{background: "white", padding:"20px 60px"}}>
   {(() => {
switch (name) {
case 'vehiculoscormerciales':
 return (
   <h1 className="titulo-categoria">Vehículos Comerciales</h1>
 )
case 'automovil':
 return (
  <h1 className="titulo-categoria">Automóvil</h1>
 )
case 'freightliner':
return (
  <h1 className="titulo-categoria">Freighliner</h1>
)
default:
 return (
  <h2>Cat&aacute;logo de productos</h2>
 )
}
})()}

   </div>

  <div className="container-fluid gridproducts" >
  {
   productos.length ==0 ? <h4>Cargando ...</h4>
   : <div>
     <Product productos={currentProducts} loading={loading}/>
   <Pagination 
   productsPerPage={productsPerPage}
   totalProducts={productos.length}
   paginate={paginate}
   />
   <p className="pagination"><b>Pagina {currentPage} de {total} </b></p>
   </div>
}

  </div>
   {/* end PRODUCT GRID */}


  </div>
  
    )
}

export default Catalogo
