import React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import image1 from './../assets/img/categoria1.png'
import image2 from './../assets/img/categoria2.png'
import image3 from './../assets/img/categoria3.png'
import imgproduct from './../assets/img/imgproduct.jpg'
import { FaSearch } from "react-icons/fa";
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import fre from './../assets/img/fre.png'
import ej2 from './../assets/img/ej2.jpg'
import ej3 from './../assets/img/ej3.jpg'
import { 
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Link
   } from 'react-router-dom'
import axios from 'axios'
import Product from '../components/Product'
import Pagination from '../components/Pagination';

const CategoriaArchive = () => {

    const [productos, setProductos] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(12);
    const [gridProductos, setGridProductos] = React.useState([]);
    const [busqueda, setBusqueda] = React.useState([]);

    const [subCategorias, setSubCategorias] = React.useState([]);

 

    const handleChange=e=>{
        setBusqueda(e.target.value);
        filtrar(e.target.value);
    }


const filtrar=(terminoBusqueda)=>{
    var resultadosBusqueda=gridProductos.filter((elemento)=>{
      if(elemento.name.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      || elemento.company.name.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      ){
        return elemento;
      }
    });
    setProductos(resultadosBusqueda);
  }

    const {name}= useParams()
    // console.log(useParams());

     React.useEffect(() => {
          var categoria = 0   
     
        // console.log("On ready");
         
        if(name == 'vehiculoscormerciales'){ var categoria = 2;}
        else if (name == 'automovil') { var categoria = 1;}
        else if (name == 'freightliner') {var categoria = 3} 
        else { var categoria = 0; }

          const subcategoriesGET = async() => {
          
            await axios.get(`https://inchcapeguatemala.com/admin/api/subcategories/${categoria}`)
                .then(response=>{
                    setSubCategorias(response.data.sub_categories);
                }).catch(error=>{
                    console.log(console.error)
                })
      
        }
        const peticionGET = async() => {
          setLoading(true)
          await axios.get(`https://inchcapeguatemala.com/admin/api/products/a6f6f0aa3af20f401a05ad1d1e5d8624ae5e3e5a41efae069708d731ea9a714a/${categoria}`)
              .then(response=>{
                let dataProducts = response.data.products;
                  if(dataProducts.status == 0){
                    
                  }else{
                    setProductos(dataProducts);
                    setGridProductos(dataProducts);
                    setLoading(false)
                  }
                  
              }).catch(error=>{
                  console.log(console.error)
              })
      }

     
        
        subcategoriesGET();
        peticionGET();
     },[name])


     //GET CURRENT PRODUCTS

     const indexOfLastProducts = currentPage * productsPerPage;
     const indexOfFirstProducts = indexOfLastProducts - productsPerPage;
     
     const paginate = pageNumber => setCurrentPage(pageNumber);
      const currentProducts = productos.slice(indexOfFirstProducts, indexOfLastProducts)
      const total = Math.ceil(productos.length / productsPerPage)
     
    return (
        <div className="">
            <div className="container-fluid" style={{background: "#cccccc"}}>
              <main className="productos-todos" >
              <div className="category2">
            <Link to={`/catalogo/categoria-automovil`}>
              <img
                src={ej2}
                alt="category-image2"
                className="category-image"
                />
                <img
                src={image2}
                alt="category2"
                className="category-image"
                />
            </Link>
            </div>
            <div className="category3">
            <Link to={`/catalogo/categoria-freightliner`}>
                <img
                src={fre}
                alt="category-image3"
                className="category-image"
                />
                <img
                src={image3}
                alt="category3"
                className="category-image"
                />
            </Link>
            </div>
            <div className="category1">
            <Link to={`/catalogo/categoria-vehiculoscormerciales`}>
                <img
                src={ej3}
                alt="category-image3"
                className="category-image"
                />
                <img
                src={image1}
                alt="category1"
                className="category-image"
                />
            </Link>
            </div>
              </main>
            </div>

       
         {/* PRODUCTOS GRID */}
  <div className="container-fluid" style={{background: "white", padding:"20px 60px"}}>
{(() => {
switch (name) {
   case 'vehiculoscormerciales':
       return (
         <h1 className="titulo-categoria">Vehículos Comerciales</h1>
       )
   case 'automovil':
       return (
        <h1 className="titulo-categoria">Automóvil</h1>
       )
    case 'freightliner':
    return (
        <h1 className="titulo-categoria">Freighliner</h1>
    )
   default:
       return (
        <h2>Todos los productos</h2>
       )
}
})()}

<div className="row">
<DropdownButton id="dropdown-basic-button" title="Categor&iacute;a">

{
  subCategorias.length ==0 ? <Dropdown.Item href="" >No hay registradas</Dropdown.Item>
  : subCategorias && subCategorias.map((subCat)=>(
    <Dropdown.Item  tag="span"  key={subCat.id}>
      <Link to={`/catalogo/categoria-${name}/${subCat.id}`}>
      {subCat.name}
      </Link>
      
    </Dropdown.Item>
    
    
      
      ))
}
</DropdownButton>
</div>
<div class="container-fluid gridproducts"> 
{
   productos.length == 0 ? <h4>No se han encontrado productos registrados dentro de esta categoría</h4>
   : <div>
     <Product productos={currentProducts} loading={loading}/>
   <Pagination 
   productsPerPage={productsPerPage}
   totalProducts={productos.length}
   paginate={paginate}
   />
     <p className="pagination"><b>Pagina {currentPage} de {total} </b></p>
   </div>
}
</div>
    
         </div>

        


        </div>
        
    )
}

export default CategoriaArchive
