import React, {Component} from "react";
import image1 from './../assets/img/categoria1.png'
import image2 from './../assets/img/categoria2.png'
import image3 from './../assets/img/categoria3.png'
import fre from './../assets/img/fre.png'
import ej2 from './../assets/img/ej2.jpg'
import ej3 from './../assets/img/ej3.jpg'
import { 
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Link
   } from 'react-router-dom'
class Main extends Component {
    render(){
        return (

        <main>
            <div className="category2">
            <Link to={`/catalogo/categoria-automovil`}>
                <img
                src={ej2}
                alt="category-image2"
                className="category-image"
                />
                <img
                src={image2}
                alt="category2"
                className="category-image"
                />
            </Link>
            </div>
            <div className="category3">
            <Link to={`/catalogo/categoria-freightliner`}>
                <img
                src={fre}
                alt="category-image3"
                className="category-image"
                />
                <img
                src={image3}
                alt="category3"
                className="category-image"
                />
            </Link>
            </div>
            <div className="category1">
            <Link to={`/catalogo/categoria-vehiculoscormerciales`}>
                <img
                src={ej3}
                alt="category-image3"
                className="category-image"
                />
                <img
                src={image1}
                alt="category1"
                className="category-image"
                />
            </Link>
            </div>
        </main>

        );
      }
}   
 
export default Main;
