import React from 'react'

const Nosotros = () => {
    return (
        <div>
            <div style={{backgroundColor: "black"}}>
                <div className="container-viajar container-fluid no-padding no-margin relative">
	                <div className="row padding-top-40 padding-bottom-40">
		                <div className="container" style={{margin: "20px auto"}}>
		                    <div className="col s12 m12 l12">
		                        <img src="https://experienciamercedes.com/guatemala/wp-content/uploads/2021/09/logomain.png" alt="logo" className="lazy responsive-img w100" style={{maxWidth: "400px", margin: "50px auto", display: "block", width: "100%"}} />
		                    </div>
                        </div>
                    </div>
        
                <div id="seccion2" className="container-viajar container-fluid no-padding no-margin relative" >   
                    <div className="container-fluid">
                        <div className="row padding-top-40 padding-bottom-40 padding-left-40 padding-right-40">

                            {/* COL START */}
                            <div className="col-12 col-sm-12 col-md-6 col-lg-3 ">
                                <div className="card">
                                    <div className="card-image">
                                        <img src="http://experienciamercedes.com/guatemala/wp-content/uploads/2020/08/8.png"/>
                                    </div>
                                    <div className="card-content">
                                        <p>1. Distribuidor y retailer de vehículos con presencia Mundial</p>
                                    </div>
                                </div>
                            </div>
                            {/* COL END */}
                          
                            {/* COL START */}
                            <div className="col-12 col-sm-12 col-md-6 col-lg-3 ">
                                <div className="card">
                                    <div className="card-image">
                                        <img src="http://experienciamercedes.com/guatemala/wp-content/uploads/2020/08/7.png"/>
                                    </div>
                                    <div className="card-content">
                                        <p>2. Cotizante en la bolsa de valores de Londrés, desde 1957, cuya casa matriz también se ubica en la ciudad antes mencionada.</p>
                                    </div>
                                </div>
                            </div>
                            {/* COL END */}
                            {/* COL START */}
                            <div className="col-12 col-sm-12 col-md-6 col-lg-3 ">
                                <div className="card">
                                    <div className="card-image">
                                        <img src="http://experienciamercedes.com/guatemala/wp-content/uploads/2020/08/6.png"/>
                                    </div>
                                    <div className="card-content">
                                        <p>3. Desarrolla sus operaciones basadas en un fuerte manejo local.</p>
                                    </div>
                                </div>
                            </div>
                            {/* COL END */}
                            {/* COL START */}
                            <div className="col-12 col-sm-12 col-md-6 col-lg-3 ">
                                <div className="card">
                                    <div className="card-image">
                                        <img src="http://experienciamercedes.com/guatemala/wp-content/uploads/2020/08/5.png"/>
                                    </div>
                                    <div className="card-content">
                                        <p>4. Liderazgo grupal de estrategias y funciones de apoyo.</p>
                                    </div>
                                </div>
                            </div>
                            {/* COL END */}
                            {/* COL START */}
                            <div className="col-12 col-sm-12 col-md-6 col-lg-3 ">
                                <div className="card">
                                    <div className="card-image">
                                        <img src="http://experienciamercedes.com/guatemala/wp-content/uploads/2020/08/4.png"/>
                                    </div>
                                    <div className="card-content">
                                        <p>5. Operaciones en 34 países, manejados en 4 regiones: Inglaterra, Europa, Asía, Américas y Africa.</p>
                                    </div>
                                </div>
                            </div>
                            {/* COL END */}
                            {/* COL START */}
                            <div className="col-12 col-sm-12 col-md-6 col-lg-3 ">
                                <div className="card">
                                    <div className="card-image">
                                        <img src="http://experienciamercedes.com/guatemala/wp-content/uploads/2020/08/3.png"/>
                                    </div>
                                    <div className="card-content">
                                        <p>6. Relación con Mercedes Benz como distribuidor por más de 30 años en el mercado inglés.</p>
                                    </div>
                                </div>
                            </div>
                            {/* COL END */}
                            {/* COL START */}
                            <div className="col-12 col-sm-12 col-md-6 col-lg-3 ">
                                <div className="card">
                                    <div className="card-image">
                                        <img src="http://experienciamercedes.com/guatemala/wp-content/uploads/2020/08/2.png"/>
                                    </div>
                                    <div className="card-content">
                                        <p>7. Alta experiencia en la región, con fuerte presencia en Chile y Perú por más de 30 años y creciendo en Colombia y centro américa en el año 2018.</p>
                                    </div>
                                </div>
                            </div>
                            {/* COL END */}
                              {/* COL START */}
                              <div className="col-12 col-sm-12 col-md-6 col-lg-3 ">
                                <div className="card">
                                    <div className="card-image">
                                        <img src="http://experienciamercedes.com/guatemala/wp-content/uploads/2020/08/1.png"/>
                                    </div>
                                    <div className="card-content">
                                        <p>8. Asociaciones de largo plazo con las marcas líderes en el mundo automotriz con las que ya se tiene una fuerte penetración de mercado en Suramérica. Enfoque de crecimiento en Centroamérica.</p>
                                    </div>
                                </div>
                            </div>
                            {/* COL END */}

                        </div> 
                    </div>       
                </div>
    
                <div className="container-viajar container-fluid no-padding no-margin relative" style={{backgroundColor: "black"}}>
                    <div className="row padding-top-40 padding-bottom-40">
                        <div className="col s12 m12 l12 padding-top-40 padding-bottom-40 padding-left-40 padding-right-40">
                            <h2 style={{color: "white", margin: "50px 10px", textAlign: "center"}} className="left-align margin-top-0" >Nuestra Estrategia</h2>
                        </div>
                    </div>
                    <div className="row" style={{  backgroundImage: "url(" + "http://experienciamercedes.com/guatemala/wp-content/uploads/2020/08/fondoe.jpg" + ")", backgroundSize: "cover"}}>
                        <div className="col s12 m12 l12 padding-top-40 padding-bottom-40 padding-left-40 padding-right-40">
                            <div className="graficos" style={{color: "white"}} >
                                <img  src="http://experienciamercedes.com/guatemala/wp-content/uploads/2020/08/grafico.png" className="lazy responsive-img w100" style={{maxWidth: "700px", margin: "0 auto", display: "block", width: "100%", padding: "20px"}} />
                            </div>
                        </div>
                    </div>        
                </div>

                <div id="seccion3" className="container-viajar container-fluid no-padding no-margin relative" style={{backgroundColor: "black"}}>
                    <div className="row ">
                        <div className="col s12 m12 l12 padding-top-40 padding-bottom-40 padding-left-40 padding-right-40">
                            <h2 style={{color: "white", margin: "60px 10px", textAlign: "center"}} className="left-align margin-top-0" >¿Por qué esta inversión?</h2>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col s12 m12 l12 padding-top-40 padding-bottom-40 padding-left-40 padding-right-40">
                            <div className="contenido" style="color:white !important;" style={{color: "white", marginBottom: "20px"}}>
                                <p>Desde hace varios meses atrás, Grupo Q entró en conversaciones con un grupo global para transferir la distribución de las marcas: Mercedes-Benz, Freightliner y Western Star, proceso que requirió una gran cantidad de información tanto de mercado, de operación local  y de país. Recientemente, se concreta la transferencia de la marca, convirtiéndose así el antiguo distribuidor en: Inchcape Guatemala, S.A., a partir de Marzo 2021.</p>
                            </div>
                        </div>
                    </div>        
                </div>
                
                <div className="row">
                    <div className="col s12 m12 l12 padding-top-20 padding-bottom-20 padding-left-40 padding-right-40">
                        <a href="https://www.inchcape.com/en/index.html" target="_blank" style={{backgroundColor: "transparent", color: "white", textDecoration: "none", textAlign: "right", float: "right", marginBottom: "40px"}}>
                            Mas informacion en www.inchcape.com
                        </a>
                    </div>
                </div>

    </div>
</div>
</div>
    )
}

export default Nosotros
