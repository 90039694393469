import React from 'react'
import Main from './../components/Main';

import Banner from './../components/Banner';
const Home = () => {
    return (
        <div>
                
                <Banner />
                <div class="title">
                    <span class="title-text">¿Qué estás buscando?</span>
                </div>
                <Main />
                <div class="space_category_footer">
                    <div></div>
                </div>
           
        </div>
    )
}

export default Home
