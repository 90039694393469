import './App.css';
import React, { useState} from "react";
import './assets/css/main.css'
import { FaWhatsapp } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";


import { 
  BrowserRouter as Router,
  Switch,
  Route,
 } from 'react-router-dom'
import Catalogo from "./pages/Catalogo";
import Home from "./pages/Home";
import Nosotros from "./pages/Nosotros";
import Sidebar from "./components/SideBar"
import Navbar from "./components/NavBar";
import Footer from './components/Footer';
import CategoriaArchive from './pages/CategoriaArchive'
import SubCategorieArchive from './pages/SubCategorieArchive'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ScrollToTop from './components/ScrollToTop';

function App() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
      setIsOpen(!isOpen)
    };
  return (
    <div>
    <Router>
      <ScrollToTop/>
    <Sidebar isOpen={isOpen} toggle={toggle} />
    <Navbar toggle={toggle} />
        <Switch>
        <Route path="/catalogo/categoria-:name/:id">
            <SubCategorieArchive/>
          </Route>
        <Route path="/catalogo/categoria-:name">
            <CategoriaArchive/>
          </Route>
            <Route path="/catalogo">
                <Catalogo/>
            </Route>
            <Route path="/nosotros">
                <Nosotros/>
            </Route>
           
            <Route path="/">
                <Home/>
            </Route>
        </Switch>
        <Footer />
    </Router>
   


    <nav className="urlcontact menu_side">
      <ul>
        <li>
          <a onClick={handleShow}>
            <i ><FaWhatsapp></FaWhatsapp></i>
            <span class="nav-text">Escríbenos</span>
          </a>
        </li>
        <li>
          <a href="#contacto">
            <i ><FaPhoneAlt></FaPhoneAlt></i>
            <span class="nav-text">Contáctanos</span>
          </a>
        </li>
      </ul>
    </nav>


    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><h1>Contáctanos</h1></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Cotiza nuestros respuestros vía WhatsApp</h4>
          <div className="container wpcontacts">
            <ul>
              <li>
                <a href="https://api.whatsapp.com/send?phone=50253070035&text=Hola%2C+quisiera+mas+informaci%C3%B3n+de+los+repuestos+Mercedes+Benz+Autom%C3%B3viles+en+el+sitio+de+Inchcape+Guatemala" target="_blank">
                  Mercedes Benz Automóviles
                </a>
              </li>
              <li>
                <a href="https://api.whatsapp.com/send?phone=50253070040&text=Hola%2C+quisiera+mas+informaci%C3%B3n+de+los+repuestos+de+Veh%C3%ADculos+Comerciales+en+el+sitio+de+Inchcape+Guatemala." target="_blank">
               Vehículos Comerciales
                </a>
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
       
        </Modal.Footer>
      </Modal>
  
</div>  
 
  );
}

export default App;
